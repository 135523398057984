const header = () => {
  const siteHeader = document.querySelector(".header")
  const headerHeight = siteHeader.offsetHeight
  let prevScrollPos = window.scrollY

  const hideHeader = () => siteHeader.classList.add("header--hide")
  const showHeader = () => siteHeader.classList.remove("header--hide")
  const setHeaderDarkMode = () => siteHeader.classList.add("header--scrolled")
  const setHeaderLightMode = () => siteHeader.classList.remove("header--scrolled")

  const handleScroll = () => {
    const currentScrollPos = window.scrollY

    // currentScrollPos > 0 - additional check for iOS, because in iOS scrollY can have a negative value

    if (currentScrollPos > prevScrollPos && currentScrollPos > 0) {
      hideHeader()
    } else {
      showHeader()
    }

    if (currentScrollPos > headerHeight) {
      setHeaderDarkMode()
    } else {
      setHeaderLightMode()
    }

    prevScrollPos = currentScrollPos
  };

  document.addEventListener("scroll", handleScroll)

  // Show header when mouse on page top
  document.addEventListener("mousemove", (event) => {
    if (event.clientY < headerHeight) showHeader()
  });
};

export default header;
