import Swiper, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
  Thumbs,
} from "swiper";

const specialistSlider = () => {
  return new Swiper(".js-specialist-slider", {
    modules: [Navigation, Pagination, Autoplay, EffectFade, Thumbs],
    loop: true,
    freeMode: false,
    speed: 2000,
    watchSlidesProgress: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".specialist-slider__nav--next",
      prevEl: ".specialist-slider__nav--prev",
    },
    breakpoints: {
      320: {
        spaceBetween: 16,
      },
      768: {
        spaceBetween: 50,
      },
      1440: {
        spaceBetween: 70,
      },
    },
  });
};

export default specialistSlider;
