import Swiper, { Navigation, Pagination } from "swiper";

const sliderInfoCards = () => {
  return new Swiper(".js-info-cards-slider", {
    modules: [Navigation, Pagination],
    loop: true,
    speed: 1000,
    direction: "horizontal",
    spaceBetween: 10,
    navigation: {
      nextEl: ".info-cards-slider__nav--next",
      prevEl: ".info-cards-slider__nav--prev",
    },
    breakpoints: {
      768: {
        enabled: false,
        noSwiping: true,
        spaceBetween: 0,
        loop: false,
      },
    },
  });
};

export default sliderInfoCards;
