import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const animLine = () => {
    gsap.registerPlugin(ScrollTrigger)
    const lines = [...document.querySelectorAll('.js-line')]

    if (lines) {
        lines.forEach(line => {
            gsap.to(line, {
                scrollTrigger: {
                    trigger: line,
                    start: 'top 80% center',
                    end: 'top 100% center',
                    toggleActions: 'restart none reverse none',
                    // markers: true,
                },
                duration: 0.5,
                width: '100%',
            })
        })
    }
}

export default animLine;
