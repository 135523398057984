import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const process = () => {
    gsap.registerPlugin(ScrollTrigger)
    const processItems = [...document.querySelectorAll('.js-process')]

    if (processItems) {
      processItems.forEach(item => {
            gsap.to(item, {
                scrollTrigger: {
                    trigger: item,
                    start: 'top 60% center',
                    end: 'top 40% center',
                    toggleActions: 'play reverse play reverse',
                    // markers: true,
                },
                duration: 0.5,
                scale: window.innerWidth < 768 ? 1.2 : 1.3,
            })
        })
    }
}

export default process
