import Swiper, { Navigation, Scrollbar } from "swiper";

const teamSlider = () => {
  return new Swiper(".js-team-slider", {
    modules: [Navigation, Scrollbar],
    enabled: false,
    loop: false,
    speed: 1000,
    slidesPerView: "auto",
    navigation: {
      nextEl: ".team-slider__nav--next",
      prevEl: ".team-slider__nav--prev",
    },
    scrollbar: {
      el: ".team-slider__scrollbar",
      draggable: true,
    },
    breakpoints: {
      768: {
        enabled: true,
        spaceBetween: 32,
      },
      1024: {
        enabled: true,
        spaceBetween: 32,
      },
      1440: {
        enabled: true,
        spaceBetween: 56,
      },
    },
  });
};

export default teamSlider;
