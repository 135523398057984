import sliderInfoCards from "./components/sliderInfoCards";
import toggleOpening from "./components/toggleOpening";
import scrollToItem from "./components/scrollToItem";
import viewSlider from "./components/viewSlider";
import specialistSlider from "./components/specialistSlider";
import progress from "./components/progress";
import serviceSlider from "./components/serviceSlider";
import productSlider from "./components/productSlider";
import header from "./components/header";
import menu from "./components/menu";
import feedback from "./components/feedback";
import map from "./components/map";
import scrollToNextSection from "./components/scrollToNextSection";
import teamMemberSlider from "./components/teamMemberSlider";
import teamSlider from "./components/teamSlider";
// import decoder from "./components/decoder";
import animLine from "./components/animLine";
import process from "./components/process";

header()
menu()
sliderInfoCards()
productSlider()
viewSlider()
serviceSlider()
toggleOpening(document.querySelector(".js-toggle-lang"))
scrollToItem(document.querySelector(".js-scroll-to-top"))
specialistSlider();
progress()
map()
scrollToNextSection()
teamMemberSlider()
teamSlider()
// decoder()
animLine()
process()
document.querySelectorAll('.js-form').forEach(form => feedback(form))
