const map = () => {
  const pins = [...document.querySelectorAll('.js-pin')]
  const thumbs = [...document.querySelectorAll('.js-thumb')]

  if (pins) {
    const resetPins = () => pins.forEach(pin => pin.classList.remove('is-active'))
    const resetThumbs = () => thumbs.forEach(thumb => thumb.classList.remove('is-active'))

    pins.forEach((item, index) => item.addEventListener('click', () => {
      resetPins()
      resetThumbs()
      item.classList.add('is-active')
      thumbs[index].classList.add('is-active')
    }))

    thumbs.forEach((item, index) => item.addEventListener('click', () => {
      resetPins()
      resetThumbs()
      item.classList.add('is-active')
      pins[index].classList.add('is-active')
    }))
  }
}

export default map;
