import Swiper, { Navigation } from "swiper";

const productSlider = () => {
  return new Swiper(".js-product-slider", {
    modules: [Navigation],
    speed: 600,
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: ".product-slider__nav--next",
      prevEl: ".product-slider__nav--prev",
    },
  });
};

export default productSlider;
