import Swiper, {Autoplay, EffectFade, Navigation, Pagination, Thumbs} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);

const viewSlider = () => {
  const swiper = new Swiper(".js-view-slider-2", {
    loop: false,
    spaceBetween: 0,
    slidesPerView: 'auto',
    freeMode: false,
    watchSlidesProgress: true,
  });

  return new Swiper(".js-view-slider", {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.view-slider__nav--next',
      prevEl: '.view-slider__nav--prev',
    },
    thumbs: {
      swiper,
    },
  })
}

export default viewSlider;
