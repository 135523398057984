const feedback = (form) => {
  // const endpoint = 'https://stage-pay-site.humoney.com/uk/forms/feedback'; // endpoint for developing feedback
  // const endpoint = "https://stage-pay-site.humoney.com/uk/forms/contact-form"; // endpoint for developing contact
  // const endpoint = 'https://aestech.eks-stage.techiia.space/en/forms/user-request';

  if (form) {
    const formType = form.attributes['data-type'].value
    const formDefaultClassName = form.attributes.class.value
    const {location} = document
    const {origin} = location
    const localization = location.pathname.substring(1, 3) || 'en'
    const endpoint = `${origin}/${localization}/forms/${formType}`;
    const allTextFields = [...form.querySelectorAll('.js-textfield')];
    const checkbox = form.querySelector('.js-checkbox');
    const checkboxInput = checkbox && checkbox.querySelector('input');

    const setInputErrorClass = input => input.classList.add('is-error');
    const removeInputErrorsClass = item => item.classList.remove('is-error');

    const setFormStatusLoading = () => form.classList.add('is-loading');
    const removeFormStatusLoading = () => form.classList.remove('is-loading');
    const setFormStatusLoaded = () => form.classList.add('is-loaded');
    const setFormStatusFailed = () => form.classList.add('is-failed');

    const resetAllInputs = () => allTextFields.forEach(input => {
      removeInputErrorsClass(input);
    });

    const resetAllErrors = () => {
      [...document.querySelectorAll('.js-error')].forEach(input => input.remove());
    };

    const resetForm = () => {
      resetAllErrors()

      setTimeout(() => {
        form.reset();
        // eslint-disable-next-line no-param-reassign
        form.className = formDefaultClassName;
      }, 5000)
    };

    const createErrorItem = (textField, message) => {
      textField.insertAdjacentHTML(
        'afterbegin',
        `
            <span class='textfield__error js-error'>
              ${message}
            </span>
        `
      );
    };

    if (checkbox) {
        checkbox.addEventListener('click', () => {
            if (checkbox.classList.contains('is-error')) {
                checkbox.classList.remove('is-error')
            }
        })
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const formData = new FormData(e.target)

      if ((checkbox && checkboxInput.checked) || checkbox === null) {
        resetAllInputs()
        resetAllErrors()
        setFormStatusLoading()

        fetch(endpoint, {
          method: 'POST',
          body: formData,
        })
          .then(res => {
            if (res.ok) {
              setFormStatusLoaded();
              resetForm();
            }
            return res.json()
          })
          .then(({ violations }) => {
            removeFormStatusLoading();

            if (violations) {
              violations.forEach(({ propertyPath, title }) => {
                allTextFields.forEach((textField) => {
                  const input = textField.querySelector(".js-input");

                  if (input && propertyPath === input.name) {
                    setInputErrorClass(textField);
                    createErrorItem(textField, title);
                  }
                });
              });
            }
          })
          .catch(() => {
            setFormStatusFailed()
            resetForm()
          })
          // .finally(() => {
            // toggleButtonDisabled(submitBtn)
          // })
      } else {
        checkbox.classList.add('is-error')
      }
    })
  }
}

export default feedback;
