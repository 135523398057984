import Swiper, { Navigation, Scrollbar } from "swiper";

const serviceSlider = () => {
  return new Swiper(".js-service-slider", {
    modules: [Navigation, Scrollbar],
    enabled: false,
    loop: false,
    speed: 1000,
    slidesPerView: 1,
    navigation: {
      nextEl: ".service-slider__nav--next",
      prevEl: ".service-slider__nav--prev",
    },
    scrollbar: {
      el: ".service-slider__scrollbar",
      draggable: true,
    },
    breakpoints: {
      768: {
        enabled: true,
        spaceBetween: 32,
        slidesPerView: "auto",
      },
      1024: {
        enabled: true,
        spaceBetween: 32,
        slidesPerView: "auto",
      },
    },
  });
};

export default serviceSlider;
