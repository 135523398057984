import Swiper, {Autoplay, EffectFade, Navigation, Pagination, Thumbs} from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);

const teamMemberSlider = () => {
  const swiper = new Swiper(".js-team-member-thumbs", {
    loop: false,
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 1000,
    freeMode: false,
    watchSlidesProgress: true,
    breakpoints: {
      768: {
        slidesPerView: 'auto',
      },
    },
  });

  return new Swiper(".js-team-member-slider", {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 10,
    effect: 'fade',
    speed: 1000,
    navigation: {
      nextEl: '.team-member-slider__next',
      prevEl: '.team-member-slider__prev',
    },
    thumbs: {
      swiper,
    },
  })
}

export default teamMemberSlider;
